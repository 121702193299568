import React from "react"

function PageImage({image, alt, caption, width, height}) {
    if (image !== '') {
        return (
            <div className="img-block">
                <img src={image} alt={alt} width={width} height={height} />
                <small className="caption">{caption}</small>
            </div>
        )
    } else {
        return '';
    }
}

export default PageImage
