import React from "react"
import Layout from "../components/layout"
import Page from "../components/page"
import PageImage from "../components/page-image"
import useContentfulImage from "../components/use-contentful-image"
import Form from "../components/form"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

export const query = graphql`
    query ($slug: String!) {
       page: contentfulContentPage(slug: {eq: $slug}) {
            slug
            title
            metaTitle
            metaDescription {
                metaDescription
            }
            leadCopy {
                raw
            }
            content {
                raw
            }
            pageImage {
                title
                description
                file {
                    url
                }
            }
        }
    }
`;

const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
        const asset = useContentfulImage(node.data.target.sys.id)
            if (asset) {
                return (
                    <img src={asset.node.file.url} alt={asset.node.title} width={asset.node.file.details.image.width} height={asset.node.file.details.image.height} />
                )
            }
        },
    },
}

function ContentPage({ data: { page } }) {

    let pageImage = page.pageImage || false
    if (page.pageImage) {
        pageImage = <PageImage image={page.pageImage.file.url} alt={page.pageImage.title} caption={page.pageImage.description} />
    } else {
        pageImage = ''
    }

    return (
        <Layout>
            <Seo
                title={page.metaTitle}
                description={page.metaDescription.metaDescription}
            />

            <Page pageTitle={page.title} pageClass="page-default">
                {pageImage}
                <div className="lead">
                    {renderRichText(page.leadCopy, options)}
                </div>
                    {renderRichText(page.content, options)}
            </Page>

            <Form />
        </Layout>
    );
}

export default ContentPage
